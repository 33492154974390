<template>
  <div class="container ftl__expansion__wrap">
    <v-row>
      <v-col cols="12" xl="7" lg="7" sm="12" md="12">
        <FAQ />
      </v-col>
      <v-col cols="12" xl="5" lg="5" sm="12" md="12">
        <popular-items />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FAQ from "./PremierClubMolecules/FAQ.vue";
import PopularItems from "./PremierClubMolecules/PopularItems.vue";
export default {
  name: "FAQsAndTopClubInfo",
  components: { FAQ, PopularItems },
};
</script>

<style></style>
