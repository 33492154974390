<template>
  <div class="ftl__widget">
    <v-card class="ftl__widget__card" elevation="0" outlined>
      <div class="pop-team d-flex">
        <div class="d-flex">
          <img class="img" src="@/assets/images/fire.svg" alt="popular-teams" />
          Popular Teams
        </div>
        <!-- <v-btn
          :ripple="false"
          href="/"
          text
          class="view-all"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          View all
          <img class="ml-2 mt-1" src="@/assets/images/blue-arrow.svg" />
        </v-btn> -->
      </div>
      <div v-if="featuredTeams.length" class="ftl__widget--chip-group">
        <v-chip
          v-for="(item, index) in featuredTeams"
          class="ftl__widget--chip mr-2 mb-2"
          link
          :key="index"
          :to="`/teams/${item.slug}`"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <div v-else class="ftl__widget--chip-group">There are no records</div>
      <!-- <div v-else>
        <v-skeleton-loader
          v-bind="{ boilerplate: false }"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </div> -->
      <!-- <v-btn
        :ripple="false"
        href="/"
        text
        class="view-all pl-5 pb-6"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        View all <img class="ml-2 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn> -->
    </v-card>
    <v-card class="ftl__widget__card" elevation="0" outlined>
      <div class="pop-team d-flex">
        <div class="d-flex">
          <img class="stadimg" src="@/assets/images/stadium.svg" alt="" />
          Popular Stadiums
        </div>
        <div>
          <!-- <v-btn
            :ripple="false"
            href="/"
            text
            class="view-all"
            v-if="!$vuetify.breakpoint.xsOnly"
          >
            View all
            <img class="ml-2 mt-1" src="@/assets/images/blue-arrow.svg"
          /></v-btn> -->
        </div>
      </div>
      <div v-if="featuredStadiums.length" class="ftl__widget--chip-group">
        <v-chip
          class="ftl__widget--chip mr-2 mb-2"
          v-for="(item, index) in featuredStadiums"
          :key="index"
          link
          :to="`/stadiums/${item.slug}`"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <div v-else class="ftl__widget--chip-group">There are no records</div>
      <!-- <div v-else>
        <v-skeleton-loader
          v-bind="{ boilerplate: false }"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </div> -->
      <!-- <v-btn
        :ripple="false"
        href="/"
        text
        class="view-all pl-5 pb-6"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        View all <img class="ml-2 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn> -->
    </v-card>
  </div>
</template>
<script>
export default {
  name: "PopularItemsMolecule",
  computed: {
    featuredTeams() {
      return this.$store.state.teams.featured;
    },
    featuredStadiums() {
      return this.$store.state.stadiums.featured;
    },
  },
};
</script>
<style></style>
