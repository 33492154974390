import collection from "@/api/fixtures";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  featured: [],
  upcoming: [],
  upcomingByType: [],
  fixtureDetails: null,
  fixturesFilters: null,
  fixturesUpcoming: [],
  fixturesUpcomingPagination: null,
};

const mutations = {
  SET_FEATURED: (state, data) => {
    state.featured = data;
  },
  SET_UPCOMING: (state, data) => {
    state.upcoming = data;
  },
  SET_UPCOMING_BY_TYPE: (state, data) => {
    state.upcomingByType = data;
  },
  SET_FIXTURES_FILTERS: (state, data) => {
    state.fixturesFilters = data;
  },
  SET_UPCOMING_FIXTURES: (state, data) => {
    state.fixturesUpcoming = data;
  },
  SET_UPCOMING_FIXTURES_PAGINATION: (state, data) => {
    state.fixturesUpcomingPagination = data;
  },
  SET_FIXTURE_DETAILS: (state, data) => {
    data.fixture_category_price.map((item) => {
      item.highlighted = false;
      item.quantity = 1;
      return item;
    });
    state.fixtureDetails = data;
  },
  UPDATE_FIXTURE_DETAILS: (state, data) => {
    state.fixtureDetails = data;
  },
};

const actions = {
  async HIGHLIGHTED({ commit, dispatch, state }, payload) {
    return await asyncMiddleware(
      async function () {
        let arr = state.fixtureDetails;
        for (let i = 0; i < arr.fixture_category_price.length; i++) {
          if (
            arr.fixture_category_price[i].stadium_category.label ===
            payload.label
          ) {
            arr.fixture_category_price[i].highlighted = true;
          } else {
            arr.fixture_category_price[i].highlighted = false;
          }
        }
        commit("UPDATE_FIXTURE_DETAILS", arr);
        return arr;
      },
      commit,
      dispatch,
      "HIGHLIGHTED",
      false
    );
  },
  async GET_FIXTURES_FILTERS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getResultsforFixturesFilter(payload);
        if (data) {
          commit("SET_FIXTURES_FILTERS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_TEAMS_FIXTURES_FILTERS",
      false
    );
  },
  async GET_FIXTURES_BY_FILTER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFixturesByFilter(payload);
        if (data) {
          commit("SET_UPCOMING_FIXTURES", data.data.data);
          let p = Object.assign({}, data.data);
          delete p.data;
          commit("SET_UPCOMING_FIXTURES_PAGINATION", p);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FIXTURES_BY_FILTER",
      false
    );
  },
  async GET_FEATURED({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFeatured(payload);
        if (data) {
          commit("SET_FEATURED", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FEATURED",
      false
    );
  },
  async GET_UPCOMING({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getUpcoming(payload);
        if (data) {
          commit("SET_UPCOMING", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_UPCOMING",
      false
    );
  },
  async GET_UPCOMING_BY_TYPE({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getUpcomingByType(payload);
        if (data) {
          commit("SET_UPCOMING_BY_TYPE", data.data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_UPCOMING_BY_TYPE",
      false
    );
  },
  async GET_FIXTURE_DETAILS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFixtureDetails(payload);
        if (data) {
          commit("SET_FIXTURE_DETAILS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FIXTURE_DETAILS",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
