import collection from "@/api/tournaments";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  featured: null,
  details: null,
};

const mutations = {
  SET_FEATURED: (state, data) => {
    state.featured = data[0];
  },
  SET_TOURNAMENT_DETAILS: (state, data) => {
    state.details = data;
  },
};

const actions = {
  async GET_FEATURED({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFeatured(payload);
        if (data) {
          commit("SET_FEATURED", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FEATURED",
      false
    );
  },
  async GET_TOURNAMENT_DETAILS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getDetails(payload);
        if (data) {
          commit("SET_TOURNAMENT_DETAILS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_TOURNAMENT_DETAILS",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
