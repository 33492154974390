export default async function asyncMiddleware(
  handler,
  commit,
  dispatch,
  id = null,
  notify = false
) {
  let response = null;
  dispatch("app/start", id, { root: true });
  try {
    response = await handler();
    dispatch(
      "app/handleSuccess",
      { type: id, response, notify },
      { root: true }
    );
  } catch (error) {
    dispatch("app/end", id, { root: true });
    dispatch("app/handleError", { type: id, error }, { root: true });
    return Promise.reject(error);
  }
  dispatch("app/end", id, { root: true });
  return response;
}
