<template>
  <div>
    <BoxSubtract :boxOptions="BxOptions">
      <template slot="box-content">
        <div class="banner-card">
          <div class="d-flex align-center justify-center">
            <v-avatar size="48" class="mr-10 xs-avatar">
              <img :src="bannerInfo.team1_logo" :alt="bannerInfo.team1_name" />
            </v-avatar>
            <v-avatar size="48" class="xs-avatar">
              <img :src="bannerInfo.team2_logo" :alt="bannerInfo.team2_name" />
            </v-avatar>
          </div>
          <div class="card-title">
            <span> {{ bannerInfo.team1_name }} </span>
            <span> vs </span>
            <span> {{ bannerInfo.team2_name }} </span>
          </div>
          <div class="date">
            <span>
              {{ bannerInfo.fixture_date | moment("DD/MM/YYYY") }}
            </span>
            <span>
              - {{ bannerInfo.fixture_date | moment("ddd") }}
              {{ convertHours(bannerInfo.start_time, "12") }}
            </span>
          </div>
          <div class="text-center pa-0">
            <v-btn
              :ripple="false"
              v-if="bannerInfo.tournament_slug && bannerInfo.fixture_slug"
              @click="goTo"
              :to="`fixtures/${bannerInfo.tournament_slug}/${bannerInfo.fixture_slug}`"
              class="btn-buy"
              elevation="0"
            >
              Buy
              <img
                class="ml-2 mt-1"
                src="../../assets/images/right-arrow.svg"
                alt="BuyBtn"
              />
            </v-btn>
          </div>
        </div>
      </template>
    </BoxSubtract>
  </div>
</template>
<script>
import TwentyFourTwelveHours from "../../mixins/Dates/TwentyFourTwelveHours";
import BoxSubtract from "./BoxSubtract.vue";
export default {
  name: "BannerCardMolecule",
  data() {
    return {
      BxOptions: {
        BgColor: "rgba(18, 5, 51, 0.9)",
        circleWidth: 35,
        width: 70,
      },
    };
  },
  mixins: [TwentyFourTwelveHours],
  props: {
    bannerInfo: {
      Type: Object,
      required: true,
    },
  },
  components: { BoxSubtract },
  methods: {
    goTo() {
      this.$router.push(
        `/fixtures/${this.bannerInfo.tournament_slug}/${this.bannerInfo.fixture_slug}`,
        () => this.$router.go(0)
      );
    },
  },
};
</script>
