import collection from "@/api/auth";
import router from "@/router";
import {
  setToken,
  setLoggedIn,
  setRefreshToken,
  getToken,
  getLoggedIn,
  getRefreshToken,
  removeToken,
  removeLoggedIn,
  removeRefreshToken,
} from "@/utils/auth";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  token: getToken(),
  refreshToken: getRefreshToken(),
  loggedInUser: getLoggedIn(),
  user: localStorage.getItem("user"),
  usersAddress: null,
  role: null,
  avatar: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_LOGGEDIN: (state, data) => {
    state.loggedInUser = data;
  },
  SET_USER: (state, data) => {
    localStorage.setItem("user", JSON.stringify(data));
    state.user = data;
  },
  SET_ROLE: (state, data) => {
    state.role = data;
  },
};

const actions = {
  async LOGIN({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.login(payload);
        if (data) {
          commit("SET_TOKEN", data.data.token);
          commit("SET_USER", data.data);
          commit("SET_ROLE", data.data.role_id);
          commit("SET_LOGGEDIN", true);
          setToken(data.data.token);
          setRefreshToken(data.data.token);
          setLoggedIn(true);
          dispatch("GET_LOGGEDIN_USER_INFO", null);
          dispatch("GET_LOGGEDIN_USER_ADDRESS", null);
          router.push("/account");
        }
        return data;
      },
      commit,
      dispatch,
      "LOGIN",
      true
    );
  },
  async SEND_PASSWORD_RESET_LINK({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.generatePasswordLink(payload);
        return data;
      },
      commit,
      dispatch,
      "SEND_PASSWORD_RESET_LINK",
      true
    );
  },
  async RESET_PASSWORD({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.resetPassword(payload);
        return data;
      },
      commit,
      dispatch,
      "RESET_PASSWORD",
      true
    );
  },
  async GET_LOGGEDIN_USER_INFO({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getLoggedInUser(payload);
        if (data) {
          commit("SET_USER", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_LOGGEDIN_USER_INFO",
      false
    );
  },
  async LOGOUT({ commit, dispatch }) {
    return await asyncMiddleware(
      async function () {
        commit("SET_TOKEN", null);
        commit("SET_LOGGEDIN", false);
        removeToken();
        removeLoggedIn();
        removeRefreshToken();
        localStorage.clear();
        sessionStorage.clear();
        router.push("/");
        router.go("/");
      },
      commit,
      dispatch,
      "LOGOUT",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
