import http from "@/utils/request";
const fixtureEndpoint = `public/order/tickets`;
const fixtureEndpointForLoggedInUser = `tickets`;
export default {
  createNewGuestOrder: (payload) =>
    http.post(`${fixtureEndpoint}/book`, payload),

  retrieveGuestOrder: (payload) =>
    http.post(`${fixtureEndpoint}/retreve`, payload),

  updateOrderQuantity: (payload) =>
    http.post(`${fixtureEndpoint}/update`, payload),

  confirmAddressGuestOrder: (payload) =>
    http.post(`${fixtureEndpoint}/confirm/address`, payload),

  // for registered user

  createOrderForLoggedinUser: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/book`, payload),

  retrieveOrderForLoggedInUser: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/get/byuser/id`, payload),

  retrieveLoggedInUsersOrders: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/get/byuser`, payload),

  updateOrderQuantityForLoggedInUser: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/update`, payload),

  confirmAddressRegisteredUserOrder: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/confirm/address`, payload),

  allocateOrderToLoggedInUser: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/allocate/user`, payload),

  // common for both guest and loggedin user

  createPaymentLink: (payload) =>
    http.post(`${fixtureEndpointForLoggedInUser}/payment/create`, payload),

  verifyPayment: (payload) =>
    http.post(`${fixtureEndpoint}/payment/verify`, payload),
};
