<template>
  <v-container>
    <div class="upcoming-events">
      <h2 class="heading" v-if="!$vuetify.breakpoint.xsOnly">
        Upcoming Football Matches
      </h2>
      <v-row v-if="upcomingFixtures.length" dense class="mt-6">
        <v-col
          v-for="item in upcomingFixtures"
          :key="item.id"
          dense
          cols="12"
          xl="6"
          lg="6"
          md="12"
          sm="12"
        >
          <event-card :event-info="item" />
        </v-col>
      </v-row>
      <v-row v-else dense class="mt-6">
        <v-col
          v-for="item in 2"
          :key="item"
          dense
          cols="12"
          xl="6"
          lg="6"
          md="12"
          sm="12"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-avatar-three-line, divider"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-card elevation="0" class="promotion-badge mt-4">
        We're a leading secondary marketplace for football tickets. Prices are
        set by sellers and may be above or below face value.
      </v-card>
    </div>
  </v-container>
</template>
<script>
import EventCard from "./EventMolecules/EventCard.vue";
export default {
  name: "TheUpcomingEvents",
  components: { EventCard },
  mounted() {
    this.$store.dispatch("fixtures/GET_UPCOMING", null);
  },
  computed: {
    upcomingFixtures() {
      return this.$store.state.fixtures.upcoming;
    },
  },
};
</script>
<style></style>
