<template>
  <div class="container sell__card__wrap">
    <v-card elevation="0" class="sell-card">
      <v-row
        class="ma-0 card__content d-flex"
        :class="$vuetify.breakpoint.smOnly ? 'align-center justify-center' : ''"
      >
        <v-col
          cols="12"
          lg="4"
          xl="3"
          md="4"
          sm="12"
          class="d-flex px-0"
          :class="
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly
              ? 'align-center justify-center'
              : ''
          "
        >
          <img class="tag-icon" src="@/assets/images/label.svg" alt="" />
          <div class="tag-name">Have a ticket to sell?</div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xl="7"
          md="5"
          sm="12"
          class="d-flex px-0"
          :class="
            $vuetify.breakpoint.smOnly ? 'align-center justify-center' : ''
          "
        >
          <p class="sell-card-info mb-0">
            Want to list your tickets on our site? Click through to find out
            more
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          xl="2"
          md="3"
          sm="12"
          class="d-flex"
          :class="
            $vuetify.breakpoint.smOnly ? 'align-center justify-center' : ''
          "
        >
          <v-btn
            to="/sell-your-tickets"
            :ripple="false"
            outlined
            class="btn-sell-ticket"
          >
            Sell Your Tickets
            <img class="ml-2" src="@/assets/images/blue-arrow.svg"
          /></v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "SellYourTicket",
};
</script>
<style></style>
