import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import VueMoment from "vue-moment";
import countryFlag from "vue-country-flags";
import Vue2Filters from "vue2-filters";
import VueScrollactive from "vue-scrollactive";

Vue.use(VueMoment);
Vue.use(countryFlag);
Vue.use(Vue2Filters);
Vue.use(VueScrollactive);

Vue.prototype.moment = VueMoment;
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
