import Cookies from "js-cookie";

const TokenKey = "Token";
const RefreshTokenKey = "RefreshToken";
const loggedIn = "IsLoggedIn";
// getter
export function getToken() {
  return Cookies.get(TokenKey);
}
export function getLoggedIn() {
  return Cookies.get(loggedIn);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

// setter
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function setLoggedIn(token) {
  return Cookies.set(loggedIn, token);
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token);
}

// remover
export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeLoggedIn() {
  return Cookies.remove(loggedIn);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}
