import collection from "@/api/static";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  faqs: "",
};

const mutations = {
  SET_FAQ(state, data) {
    state.faqs = data.toString();
  },
};

const actions = {
  async CONTACT_US({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.contactUs(payload);
        return data;
      },
      commit,
      dispatch,
      "CONTACT_US",
      true
    );
  },
  async NEWS_LETTER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.newsLetter(payload);
        return data;
      },
      commit,
      dispatch,
      "NEWS_LETTER",
      true
    );
  },

  async SELL_YOUR_TICKET({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.sellYourTicket(payload);
        return data;
      },
      commit,
      dispatch,
      "SELL_YOUR_TICKET",
      true
    );
  },
  async FAQS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.staticByKey(payload);
        commit("SET_FAQ", data.data.value);
        return data;
      },
      commit,
      dispatch,
      "FAQS",
      true
    );
  },

  async STATIC_DATA_BY_KEY({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.staticByKey(payload);
        return data;
      },
      commit,
      dispatch,
      "STATIC_DATA_BY_KEY",
      true
    );
  },

  async ALL_BLOG({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.allBlogs(payload);
        return data;
      },
      commit,
      dispatch,
      "ALL_BLOG",
      true
    );
  },

  async BLOG_BY_ID({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.blogById(payload);
        return data;
      },
      commit,
      dispatch,
      "BLOG_BY_ID",
      true
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
