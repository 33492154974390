import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import "@mdi/light-font/css/materialdesignicons-light.css";
import "@/assets/scss/app.scss";
Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#BCED24",
        secondary: "#0D0033",
        lightGrey: "#F2F2F3",
      },
    },
  },
};

export default new Vuetify(opts);
