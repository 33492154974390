import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "*",
    name: "404",
    // component: () => import('@/views/404.vue')
    component: () => import(/* webpackChunkName: "404" */ "../views/404"),
  },
  {
    path: "/fixtures/:tournamentSlug/:fixtureSlug",
    name: "Fixtures",

    component: () =>
      import(/* webpackChunkName: "Fixtures" */ "../views/Fixtures"),
  },
  {
    path: "/tournaments/:tournamentSlug",
    name: "Tournaments",

    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Tournaments.vue"),
  },
  {
    path: "/stadiums/:stadiumSlug",
    name: "Stadiums",

    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Stadiums.vue"),
  },
  {
    path: "/teams/:teamSlug",
    name: "Teams",

    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Teams.vue"),
  },
  {
    path: "/checkout/:orderSlug",
    name: "Checkout",

    component: () =>
      import(/* webpackChunkName: "Checkout" */ "../views/Checkout.vue"),
  },
  {
    path: "/most-popular-matches",
    name: "MostPopularMatches",

    component: () =>
      import(
        /* webpackChunkName: "Checkout" */ "../views/MostPopularMatches.vue"
      ),
  },
  {
    path: "/summary",
    name: "Summary",

    component: () =>
      import(/* webpackChunkName: "Summary" */ "../views/Summary.vue"),
  },
  {
    path: "/sell-your-tickets",
    name: "Sell Your Tickets",

    component: () =>
      import(
        /* webpackChunkName: "SellYourTickets" */
        "../views/SellYourTickets.vue"
      ),
  },
  {
    path: "/contact-us",
    name: "Contact Us",

    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "../views/ContactUs.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",

    component: () =>
      import(/* webpackChunkName: "AboutUs" */ "../views/AboutUs.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy And Policy",

    component: () =>
      import(
        /* webpackChunkName: "Privacy" */
        "../views/Privacy.vue"
      ),
  },
  {
    path: "/cookies",
    name: "Cookies",

    component: () =>
      import(
        /* webpackChunkName: "Cookies" */
        "../views/Cookies.vue"
      ),
  },
  {
    path: "/search",
    name: "Search Results",

    component: () =>
      import(
        /* webpackChunkName: "Search" */
        "../views/Search.vue"
      ),
  },
  {
    path: "/blog",
    name: "Blog",

    component: () =>
      import(/* webpackChunkName: "Blog" */ "../views/Blog/index.vue"),
  },
  {
    path: "/blog-post/:slug",
    name: "Blog Details",

    component: () =>
      import(/* webpackChunkName: "BlogPost" */ "../views/Blog/Post.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "Terms And Conditions",

    component: () =>
      import(
        /* webpackChunkName: "TermsAndConditions" */
        "../views/TermsAndConditions.vue"
      ),
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",

    component: () =>
      import(
        /* webpackChunkName: "Disclaimer" */
        "../views/Disclaimer.vue"
      ),
  },
  {
    path: "/faqs",
    name: "FAQs",

    component: () =>
      import(
        /* webpackChunkName: "TermsAndConditions" */
        "../views/faqs.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/password",
    name: "password",
    meta: { title: "Password" },
    component: () =>
      import(/* webpackChunkName: "Password Reset" */ "../views/Auth/Password"),
    children: [
      {
        path: "reset",
        meta: { title: "Password Reset Email" },
        component: () =>
          import(
            /* webpackChunkName: "Password Reset" */ "../views/Auth/Password/Email"
          ),
      },
      {
        path: "reset/:token",
        meta: { title: "Password Reset Confirmation" },
        component: () =>
          import(
            /* webpackChunkName: "Password Reset" */ "../views/Auth/Password/Reset"
          ),
      },
    ],
  },
  {
    path: "/account",
    name: "Account",

    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
  },
  {
    path: "/payment/:id",
    name: "Payment",

    component: () =>
      import(/* webpackChunkName: "Summary" */ "../views/Payment.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/account"];
  const authRequired = publicPages.includes(to.path);
  const loggedIn = store.getters.token;

  if (authRequired && !loggedIn) {
    return next("/login");
  }
  next();
});

export default router;
