import collection from "@/api/stadiums";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  featured: [],
  details: null,
  nearByStadiums: [],
  nearByTeams: [],
};

const mutations = {
  SET_FEATURED: (state, data) => {
    state.featured = data;
  },
  SET_DETAILS: (state, data) => {
    state.details = data;
  },
  SET_NEARBY_TEAMS: (state, data) => {
    state.nearByTeams = data;
  },
  SET_NEARBY_STADIUMS: (state, data) => {
    state.nearByStadiums = data;
  },
};

const actions = {
  async GET_FEATURED({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFeatured(payload);
        if (data) {
          commit("SET_FEATURED", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FEATURED",
      false
    );
  },
  async GET_DETAILS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getDetails(payload);
        if (data) {
          commit("SET_DETAILS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_DETAILS",
      false
    );
  },
  async GET_NEARBY_TEAMS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getNearByTeams(payload);
        if (data) {
          commit("SET_NEARBY_TEAMS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_NEARBY_TEAMS",
      false
    );
  },
  async GET_NEARBY_STADIUMS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getNearByStadiums(payload);
        if (data) {
          commit("SET_NEARBY_STADIUMS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_NEARBY_STADIUMS",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
