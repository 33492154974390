import http from "@/utils/request";
const fixtureEndpoint = `public/home/stadium`;
export default {
  getFeatured: (payload) =>
    http.post(`${fixtureEndpoint}/featured/get`, payload),
  getDetails: (payload) => http.post(`public/stadium/get`, payload),
  getNearByTeams: (payload) =>
    http.post(`public/stadium/get/nearby/teams`, payload),
  getNearByStadiums: (payload) =>
    http.post(`public/stadium/get/nearby/stadiums`, payload),
};
