<template>
  <div>
    <v-card class="event-card" elevation="0">
      <v-row
        class="ma-0 py-2"
        :class="$vuetify.breakpoint.xsOnly ? '' : 'px-4'"
      >
        <v-col
          cols="12"
          xl="1"
          lg="1"
          md="1"
          sm="1"
          class="pa-0"
          :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''"
        >
          <div class="event-date py-3">
            <div class="date">
              {{ eventInfo.fixture_date | moment("DD") }}
            </div>
            <div class="month">
              {{ eventInfo.fixture_date | moment("MMM") }}
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="11"
          lg="11"
          md="11"
          sm="11"
          class="pa-0 event-info"
          :class="$vuetify.breakpoint.xsOnly ? 'mt-n9 pa-0' : 'pl-4'"
        >
          <div
            class="d-flex align-center justify-space-between pb-2"
            :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
          >
            <div
              class="fixture-sec d-flex"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'flex-column justify-center'
                  : 'justify-space-between'
              "
            >
              <div class="logo">
                <v-avatar class="avatar-logo">
                  <img
                    class="logo1"
                    :src="eventInfo.team1_logo"
                    :alt="`Team1${eventInfo.team1_name}`"
                  />
                </v-avatar>
                <span class="match-between">vs</span>
                <v-avatar class="avatar-logo">
                  <img
                    class="logo2"
                    :src="eventInfo.team2_logo"
                    :alt="`Team2${eventInfo.team2_name}`"
                  />
                </v-avatar>
              </div>
              <div
                class="details"
                :class="$vuetify.breakpoint.xsOnly ? 'text-center mt-3' : ''"
              >
                <h3 class="event-type">
                  {{ eventInfo.tournament_name }}
                </h3>
                <h2 class="event-title">
                  <span>
                    {{ eventInfo.team1_name }}
                  </span>
                  <span> vs </span>
                  <span>
                    {{ eventInfo.team2_name }}
                  </span>
                </h2>
              </div>
            </div>
            <div class="buy-sec" v-if="!$vuetify.breakpoint.xsOnly">
              <v-btn
                :ripple="false"
                v-if="eventInfo.tournament_slug && eventInfo.fixture_slug"
                class="btn-buy"
                elevation="0"
                @click.prevent="goTo"
              >
                Buy
                <img
                  class="ml-2 mt-1"
                  src="../../assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </div>
          <v-divider class="line"></v-divider>
          <div
            class="d-flex align-center justify-space-between pt-2"
            :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
          >
            <div
              class="address-info d-flex align-center"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
            >
              <div class="d-flex location justify-center align-center">
                <img
                  class="courticon"
                  src="../../assets/images/footballcourt.png"
                  alt=""
                />
                <span> Kickoff : </span>
                <span class="ml-1" style="font-weight: 800">
                  {{ convertHours(eventInfo.start_time, "12") }}
                </span>
                <span class="ml-1 mr-1"> | </span>
              </div>
              <div class="d-flex">
                <div class="d-flex location justify-center align-center">
                  <span>
                    {{ eventInfo.stadium_name | capitalize }},
                    {{ eventInfo.stadium_city | capitalize }},
                    {{
                      eventInfo.stadium_country
                        ? eventInfo.stadium_country
                        : "United Kingdom" | capitalize
                    }}
                  </span>
                  <!-- <v-avatar class="ml-1 mt-1" size="16">
                  <countryflag rounded iso="gb" />
                </v-avatar> -->
                </div>
                <img class="flaguk" src="@/assets/images/uk-flag.svg" alt="" />
              </div>
            </div>
            <div v-if="$vuetify.breakpoint.xsOnly">
              <v-btn
                :ripple="false"
                v-if="eventInfo.tournament_slug && eventInfo.fixture_slug"
                class="btn-buy xs-buy"
                elevation="0"
                @click.prevent="goTo"
              >
                Buy
                <img
                  class="ml-2"
                  src="../../assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
            <div v-if="eventInfo.lowest_price" class="price">
              Tickets from:
              <span> {{ eventInfo.lowest_price.price | currency("£") }} </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import TwentyFourTwelveHours from "../../mixins/Dates/TwentyFourTwelveHours";
export default {
  name: "EventCardMolecule",
  mixins: [TwentyFourTwelveHours],
  props: {
    eventInfo: {
      Type: Object,
      required: true,
    },
  },
  methods: {
    goTo() {
      this.$router.push(
        `/fixtures/${this.eventInfo.tournament_slug}/${this.eventInfo.fixture_slug}`,
        () => this.$router.go(0)
      );
    },
  },
};
</script>
<style></style>
