<template>
  <div class="find-tickets-wrapper">
    <div>
      <BoxSubtract
        :boxOptions="$vuetify.breakpoint.smAndDown ? BxOptionsXs : BxOptionsLg"
      >
        <template slot="box-content">
          <div class="search-wrapper">
            <div class="search-form" outlined>
              <div
                class="search-form-wrap d-flex align-center justify-center"
                :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
              >
                <div
                  class="search-txt"
                  :class="$vuetify.breakpoint.xsOnly ? 'mb-3' : ''"
                >
                  Find your tickets
                </div>
                <div
                  class="date d-flex align-center justify-center"
                  :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-2'"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="filterPayload.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateUkFormat(filterPayload.start_date)"
                        hide-details="auto"
                        class="date-input vertical"
                        v-on="on"
                        v-bind="attrs"
                        placeholder="Date from"
                      >
                        <template slot="prepend-inner">
                          <img
                            class="calendar"
                            src="@/assets/images/calendar.svg"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="filterPayload.start_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        :ripple="false"
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :ripple="false"
                        text
                        color="primary"
                        @click="$refs.menu.save(filterPayload.start_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="filterPayload.end_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateUkFormat(filterPayload.end_date)"
                        hide-details="auto"
                        class="date-input"
                        v-bind="attrs"
                        v-on="on"
                        placeholder="Date to"
                      >
                        <template slot="prepend-inner">
                          <img
                            class="calendar"
                            src="@/assets/images/calendar.svg"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="filterPayload.end_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(filterPayload.end_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="auto-wrapper">
                  <v-autocomplete
                    v-model="filterPayload.team_id"
                    :items="teams"
                    :loading="isLoading"
                    :search-input.sync="search"
                    clearable
                    multiple
                    hide-details="auto"
                    hide-selected
                    item-text="title"
                    item-value="id"
                    placeholder="City / Team"
                    class="date-input xs-location"
                    :menu-props="{ maxHeight: 400 }"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for your favorite
                          <strong>Teams</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <!-- <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="secondary"
                        class="white--text"
                        v-on="on"
                        close
                        @click="remove(item.id)"
                        @click:close="remove(item.id)"
                      >
                        <span v-text="item.title"></span>
                      </v-chip>
                    </template> -->
                    <template v-slot:item="{ item }">
                      <v-avatar left class="mr-2">
                        <v-img :src="item.logo"></v-img>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
                <v-btn @click="searchFixtures" class="search-btn">
                  <img src="@/assets/images/search-arrow.png" alt="" />
                </v-btn>
              </div>
            </div>
          </div>
        </template>
      </BoxSubtract>
    </div>
    <!-- <v-card class="search-form" outlined>
      <div
        class="d-flex align-center justify-center"
        :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
      >
        <div
          class="search-txt"
          :class="$vuetify.breakpoint.xsOnly ? 'mb-3' : ''"
        >
          Find your tickets
        </div>
        <div
          class="date d-flex align-center justify-center"
          :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-2'"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="filterPayload.start_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateUkFormat(filterPayload.start_date)"
                hide-details="auto"
                class="date-input vertical"
                v-on="on"
                v-bind="attrs"
                placeholder="Date from"
              >
                <template slot="prepend-inner">
                  <img class="calendar" src="@/assets/images/calendar.svg" />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="filterPayload.start_date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn :ripple="false" text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                :ripple="false"
                text
                color="primary"
                @click="$refs.menu.save(filterPayload.start_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="filterPayload.end_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateUkFormat(filterPayload.end_date)"
                hide-details="auto"
                class="date-input"
                v-bind="attrs"
                v-on="on"
                placeholder="Date to"
              >
                <template slot="prepend-inner">
                  <img class="calendar" src="@/assets/images/calendar.svg" />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="filterPayload.end_date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu2.save(filterPayload.end_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div :class="$vuetify.breakpoint.xsOnly ? 'my-3' : 'mr-2'">
          <v-autocomplete
            v-model="filterPayload.team_id"
            :items="teams"
            :loading="isLoading"
            :search-input.sync="search"
            chips
            clearable
            multiple
            hide-details="auto"
            hide-selected
            item-text="title"
            item-value="id"
            placeholder="City / Team"
            class="date-input xs-location"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for your favorite
                  <strong>Teams</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="secondary"
                class="white--text"
                v-on="on"
                close
                @click="remove(item.id)"
                @click:close="remove(item.id)"
              >
                <span v-text="item.title"></span>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-avatar left class="mr-2">
                <v-img :src="item.logo"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-btn @click="searchFixtures" class="search-btn">
          <img src="@/assets/images/search-arrow.png" alt="" />
        </v-btn>
      </div>
    </v-card> -->
  </div>
</template>
<script>
import DateFormat from "@/mixins/Dates/ukFormat.js";
import BoxSubtract from "@/components/BannerMolecules/BoxSubtract.vue";
export default {
  name: "SearchCard",
  mixins: [DateFormat],
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    filterPayload: {
      start_date: "",
      end_date: "",
      team_id: [],
    },
    isLoading: false,
    search: null,
    tab: null,
    BxOptionsLg: {
      BgColor: "#BCED24",
      circleWidth: 10,
      width: 10,
    },
    BxOptionsXs: {
      BgColor: "#BCED24",
      circleWidth: 30,
      width: 60,
    },
  }),
  components: {
    BoxSubtract,
  },
  computed: {
    teams() {
      if (this.$store.state.app.menus) return this.$store.state.app.menus.teams;
      else return [];
    },
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (val && this.teams.length > 0) return;

      this.isLoading = true;

      // Lazily load input teams
      this.$store
        .dispatch("app/MENU", {
          getters: ["teams", "tournaments", "stadiums"],
        })
        .then((res) => res.clone().json())
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    async searchFixtures() {
      await this.$store
        .dispatch("fixtures/GET_FIXTURES_BY_FILTER", this.filterPayload)
        .then(() => {
          this.$router.push("/search");
        });
    },
    remove(item) {
      const index = this.filterPayload.team_id.indexOf(item);
      if (index >= 0) this.filterPayload.team_id.splice(index, 1);
    },
  },
};
</script>
