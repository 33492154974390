import collection from "@/api/orders";
import router from "@/router";
import asyncMiddleware from "@/utils/ApiHandler.js";
import {
  setToken,
  setRefreshToken,
  // removeToken,
  // removeRefreshToken,
} from "@/utils/auth";

const state = {
  orderSlug: null,
  orderDetail: null,
  loggedInUsersOrders: [],
  loggedInUsersOrdersPagination: null,
  orderSummary: null,
  orderSessionExpiry: localStorage.getItem("ordrexp") | null,
};

const mutations = {
  SET_ORDER_SLUG: (state, data) => {
    state.orderSlug = data;
  },
  SET_ORDER_SESSION: (state, data) => {
    localStorage.setItem("ordrexp", JSON.stringify(data));
    state.orderSessionExpiry = data;
  },
  SET_ORDER_DETAILS: (state, data) => {
    state.orderDetail = data;
  },
  SET_USERS_ORDERS: (state, data) => {
    state.loggedInUsersOrders = data;
  },
  SET_USERS_ORDERS_PAGINATION: (state, data) => {
    state.loggedInUsersOrdersPagination = data;
  },
  SET_GUEST_ORDER_SUMMARY: (state, data) => {
    state.orderSummary = data;
  },
};

const actions = {
  async CREATE_GUEST_ORDER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.createNewGuestOrder(payload);
        if (data) {
          commit("SET_ORDER_SLUG", data.data.slug);
          commit("SET_ORDER_SESSION", data.data.booking_auto_cancel);
        }
        return data;
      },
      commit,
      dispatch,
      "CREATE_GUEST_ORDER",
      true
    );
  },
  async UPDATE_ORDER_QUANTITY({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.updateOrderQuantity(payload);
        if (data) {
          commit("SET_ORDER_SESSION", data.data.booking_auto_cancel);
        }
        return data;
      },
      commit,
      dispatch,
      "UPDATE_ORDER_QUANTITY",
      true
    );
  },
  async GET_GUEST_ORDER_DETAILS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.retrieveGuestOrder(payload);
        if (data) {
          commit("SET_ORDER_DETAILS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_GUEST_ORDER_DETAILS",
      true
    );
  },
  async CONFIRM_ADDRESS_ORDER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.confirmAddressGuestOrder(payload);
        if (data) {
          setToken(data.data.token);
          setRefreshToken(data.data.token);
        }
        return data;
      },
      commit,
      dispatch,
      "CONFIRM_ADDRESS_ORDER",
      true
    );
  },
  // for logged in user
  async RETRIEVE_ORDERS_OF_LOGGEDIN_USER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.retrieveOrderForLoggedInUser(payload);
        if (data) {
          commit("SET_ORDER_DETAILS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "RETRIEVE_ORDERS_OF_LOGGEDIN_USER",
      true
    );
  },
  async CREATE_ORDER_FOR_LOGGEDIN_USER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.createOrderForLoggedinUser(payload);
        if (data) {
          commit("SET_ORDER_SLUG", data.data.slug);
          commit("SET_ORDER_SESSION", data.data.booking_auto_cancel);
        }
        return data;
      },
      commit,
      dispatch,
      "CREATE_ORDER_FOR_LOGGEDIN_USER",
      true
    );
  },
  async UPDATE_ORDER_QUANTITY_FOR_REGISTERED_USER(
    { commit, dispatch },
    payload
  ) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.updateOrderQuantityForLoggedInUser(
          payload
        );
        if (data) {
          commit("SET_ORDER_SESSION", data.data.booking_auto_cancel);
        }
        return data;
      },
      commit,
      dispatch,
      "UPDATE_ORDER_QUANTITY_FOR_REGISTERED_USER",
      true
    );
  },
  async ALLOCATE_USER_ADDRESS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.allocateOrderToLoggedInUser(payload);
        return data;
      },
      commit,
      dispatch,
      "ALLOCATE_USER_ADDRESS",
      true
    );
  },
  async CONFIRM_ADDRESS_ORDER_FOR_REGISTERED_USER(
    { commit, dispatch },
    payload
  ) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.confirmAddressRegisteredUserOrder(
          payload
        );
        // if (data) {
        //   setToken(data.data.token);
        //   setRefreshToken(data.data.token);
        // }
        return data;
      },
      commit,
      dispatch,
      "CONFIRM_ADDRESS_ORDER",
      true
    );
  },
  // for both guest and loggedin users
  async CREATE_PAYMENT_LINK({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.createPaymentLink(payload);
        if (data) {
          window.location.href = data.data.url;
        }
        return data;
      },
      commit,
      dispatch,
      "CREATE_PAYMENT_LINK",
      true
    );
  },
  async VERIFY_PAYMENT({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.verifyPayment(payload);
        if (data) {
          commit("SET_GUEST_ORDER_SUMMARY", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "VERIFY_PAYMENT",
      true
    );
  },
  async RESET({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        commit("SET_ORDER_SLUG", payload);
        commit("SET_ORDER_SESSION", "");
        commit("SET_ORDER_DETAILS", payload);
        // removeToken();
        // removeRefreshToken();
        router.push("/");
        router.go("/");
        return payload;
      },
      commit,
      dispatch,
      "RESET",
      false
    );
  },
  async RETRIEVE_LOGGEDIN_USERS_ORDERS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.retrieveLoggedInUsersOrders(payload);
        if (data) {
          commit("SET_USERS_ORDERS", data.data.data);
          let p = Object.assign({}, data.data);
          delete p.data;
          commit("SET_USERS_ORDERS_PAGINATION", p);
        }
        return data;
      },
      commit,
      dispatch,
      "RETRIEVE_LOGGEDIN_USERS_ORDERS",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
