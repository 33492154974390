<template>
  <v-system-bar class="mt-4 features__wrap bg-grey-light" height="133">
    <v-row class="features" v-if="!$vuetify.breakpoint.xsOnly">
      <template v-for="(item, index) in items">
        <v-col
          class="features-list"
          cols="12"
          lg="2"
          xl="2"
          sm="4"
          md="2"
          :key="index"
        >
          <div><img :src="item.icon" alt="" /></div>
          {{ item.title }}
        </v-col>
      </template>
    </v-row>
    <v-carousel class="xs-features" v-if="$vuetify.breakpoint.xsOnly">
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-row
          class="features fill-height flex-column"
          align="center"
          justify="center"
        >
          <div class="feature-cont">
            <img :src="item.icon" class="feat-img" alt="" />
            <div class="features-list font-weight-bold">{{ item.title }}</div>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-system-bar>
</template>
<script>
export default {
  name: "TheFeatures",
  data() {
    return {
      items: [
        {
          title: "Ticket Order Guarantee",
          icon: require("@/assets/images/medal.svg"),
        },
        {
          title: "Valid & Authentic Tickets",
          icon: require("@/assets/images/ticket.svg"),
        },
        {
          title: "Delivered in Time",
          icon: require("@/assets/images/delivery.svg"),
        },
        {
          title: "Cheap & Competitive",
          icon: require("@/assets/images/money.svg"),
        },
        {
          title: "Secure & Safe Transaction",
          icon: require("@/assets/images/shield.svg"),
        },
        {
          title: "Customer Care Team",
          icon: require("@/assets/images/headphone.svg"),
        },
      ],
    };
  },
};
</script>
<style></style>
