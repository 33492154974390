<template>
  <v-system-bar class="py-2 top-navbar bg-grey-light" height="35">
    <v-flex class="d-flex top__navbar__content">
      <div class="ml-auto signin">
        <span v-if="$store.getters.token && $store.state.auth.loggedInUser">
          <v-icon> mdi-account </v-icon>
          <router-link to="/account"> My Account </router-link>
          <!-- <a
            href="javascript:void(0)"
            class="mr-2"
            @click.prevent="$store.dispatch('auth/LOGOUT')"
          >
            Log out
          </a> -->
        </span>
        <span v-else @click="login">
          <img src="../../assets/images/signin.svg" alt="" />
          <a class="mr-2">Sign In</a>
        </span>
        <span class="ml-2">
          | UK Tel:
          <a
            class="font-16 line-h-14 font-weight--regular text-color-secondary-300"
            href="tel:08712845277"
          >
            0871 284 5277
          </a>
        </span>
      </div>
    </v-flex>
  </v-system-bar>
</template>
<script>
export default {
  name: "TheNavbar",
  methods: {
    login() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
