import collection from "@/api/teams";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  featured: [],
  teamDetails: null,
  nearbyTeams: [],
  nearbyStadiums: [],
  teamsFixtures: [],
  teamsFixturesPagination: null,
};

const mutations = {
  SET_FEATURED: (state, data) => {
    state.featured = data;
  },
  SET_TEAM: (state, data) => {
    state.teamDetails = data;
  },
  SET_TEAMS_FIXTURES: (state, data) => {
    state.teamsFixtures = data;
  },
  SET_TEAMS_FIXTURES_PAGINATION: (state, data) => {
    state.teamsFixturesPagination = data;
  },
  SET_NEARBY_TEAMS: (state, data) => {
    state.nearbyTeams = data;
  },
  SET_NEARBY_STADIUMS: (state, data) => {
    state.nearbyStadiums = data;
  },
};

const actions = {
  async GET_FEATURED({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getFeatured(payload);
        if (data) {
          commit("SET_FEATURED", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_FEATURED",
      false
    );
  },
  async GET_TEAM_DETAILS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        commit("SET_TEAM", null);
        const { data } = await collection.getTeamDetailsBySlug(payload);
        if (data) {
          commit("SET_TEAM", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_TEAM_DETAILS",
      false
    );
  },
  async GET_TEAMS_FIXTURES({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        commit("SET_TEAMS_FIXTURES", []);
        commit("SET_TEAMS_FIXTURES_PAGINATION", null);
        const { data } = await collection.getTeamsFixtures(payload);
        if (data) {
          commit("SET_TEAMS_FIXTURES", data.data.data);
          let p = Object.assign({}, data.data);
          delete p.data;
          commit("SET_TEAMS_FIXTURES_PAGINATION", p);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_TEAMS_FIXTURES",
      false
    );
  },

  async GET_NEARBY_TEAMS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getNearbyTeams(payload);
        if (data) {
          commit("SET_NEARBY_TEAMS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_NEARBY_TEAMS",
      false
    );
  },
  async GET_NEARBY_STADIUMS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getNearbyStadiums(payload);
        if (data) {
          commit("SET_NEARBY_STADIUMS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_NEARBY_STADIUMS",
      false
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
