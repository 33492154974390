<template>
  <div v-if="featuredTournaments" class="container premier-league">
    <h2 class="premier-title">
      {{ featuredTournaments.title }}
    </h2>
    <v-row class="premier-sec" v-if="!$vuetify.breakpoint.xsOnly">
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="6"
        class="premier-col"
        v-for="item in featuredTournaments.tournament_team"
        :key="item.id"
      >
        <premier-league-card :teamInfo="item" />
      </v-col>
    </v-row>
    <v-carousel
      hide-delimiter-background
      cycle
      class="xs-features tournaments"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-carousel-item
        v-for="item in featuredTournaments.tournament_team"
        :key="item.id"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-row class="premier-sec fill-height" align="center" justify="center">
          <v-col cols="12" xl="3" lg="3" md="3" sm="6" class="premier-col">
            <premier-league-card :teamInfo="item" />
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <div class="view-all">
      <v-btn
        :ripple="false"
        class="text-capitalize"
        :to="`/tournaments/${featuredTournaments.slug}`"
        text
      >
        View all Clubs
        <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn>
      <v-divider class="line"></v-divider>
    </div>
  </div>
  <div v-else class="container premier-league">
    <h2 class="premier-title">
      <v-skeleton-loader
        v-bind="{ boilerplate: false }"
        type="heading"
      ></v-skeleton-loader>
    </h2>
    <v-row class="premier-sec">
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="6"
        class="premier-col"
        v-for="item in 5"
        :key="item"
      >
        <v-skeleton-loader
          v-bind="{ boilerplate: false }"
          type="card-avatar, article, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PremierLeagueCard from "../components/PremierLeagueMolecules/PremierLeagueCard.vue";
export default {
  name: "ThePremierLeague",
  components: {
    PremierLeagueCard,
  },
  mounted() {
    this.loadData();
  },
  computed: {
    featuredTournaments() {
      return this.$store.state.tournaments.featured;
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("tournaments/GET_FEATURED", null);
      await this.$store.dispatch("teams/GET_FEATURED", null);
      await this.$store.dispatch("stadiums/GET_FEATURED", null);
    },
  },
};
</script>
<style></style>
