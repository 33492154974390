const state = {
  alert: null,
};

const mutations = {
  START_LOADING(state, data) {
    state.alert = data;
    state.alert.isOpen = true;
  },
  STOP_LOADING(state) {
    state.alert = null;
  },
};

const actions = {
  OPEN({ commit }, payload) {
    commit("START_LOADING", payload);
  },
  CLOSE({ commit }) {
    commit("STOP_LOADING");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
