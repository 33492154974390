import http from "@/utils/request";
const userAddressEndpoint = `user-address`;
export default {
  getAddress: (payload) =>
    http.post(`${userAddressEndpoint}/get/byuser`, payload),
  createAddress: (payload) => http.post(`${userAddressEndpoint}`, payload),
  updateAdrress: (id, payload) =>
    http.put(`${userAddressEndpoint}/${id}`, payload),
  updateUser: (payload) => http.post(`profile/update`, payload),
};
