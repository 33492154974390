<template>
  <section v-if="featuredFixtures" class="banner-wrap">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pa-0">
          <v-carousel
            v-model="model"
            class="slider"
            show-arrows-on-hover
            hide-delimiters
          >
            <v-carousel-item
              v-for="(item, i) in featuredFixtures"
              :key="i"
              :src="item.image"
            >
              <div class="banner-card-wrap">
                <banner-card :banner-info="item" />
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import BannerCard from "./BannerMolecules/BannerCard.vue";
export default {
  components: { BannerCard },
  name: "TheBanner",
  data: () => ({
    model: 0,
    items: [
      {
        title: "Order Guarantee",
        src: require("@/assets/images/bannermiddle.png"),
      },
      {
        title: "Valid & Authentic Tickets",
        src: require("@/assets/images/bannermiddle.png"),
      },
      {
        title: "Secure & Safe Transaction",
        src: require("@/assets/images/bannermiddle.png"),
      },
      {
        title: "Customer Care Team",
        src: require("@/assets/images/bannermiddle.png"),
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("fixtures/GET_FEATURED", null);
  },
  computed: {
    bannerArrows() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },
    featuredFixtures() {
      return this.$store.state.fixtures.featured;
    },
  },
};
</script>
<style></style>
