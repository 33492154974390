<template>
  <div class="ftl__expansion__sec">
    <div
      class="header"
      :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : ''"
    >
      <h2 class="title">Frequently Asked Questions</h2>
      <div>
        <v-btn
          :ripple="false"
          text
          class="view-all"
          v-if="!$vuetify.breakpoint.xsOnly"
          to="/faqs"
        >
          View all <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
        /></v-btn>
      </div>
    </div>
    <v-card class="ftl__expansion__row" elevation="0">
      <v-expansion-panels v-model="panel" class="ftl__expansion" flat>
        <template v-for="(item, index) in items">
          <v-expansion-panel expand class="ftl__expansion__panel" :key="index">
            <v-expansion-panel-header class="ftl__expansion__header">
              <span :class="$vuetify.breakpoint.xsOnly ? 'pr-10' : ''">
                {{ item.question }}</span
              >
              <template v-slot:actions>
                <v-icon color="#070B32"> mdi-plus </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ftl__expansion__content">
              <div class="d-block" v-html="item.answer"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-card>
    <div
      class="d-flex align-center justify-center flex-column"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-btn to="/faqs" :ripple="false" text class="view-all">
        View all <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn>
      <v-divider
        style="width: 71px; border: 1px solid rgba(13, 0, 51, 0.5)"
      ></v-divider>
    </div>
  </div>
</template>
<script>
export default {
  name: "FAQ",
  data: () => ({
    loading: false,
    readonly: false,
    panel: null,
    items: [
      {
        question: `Are my tickets valid? `,
        answer: `<p>Yes, all tickets purchased through <strong>https://footballticketslive.com</strong> are 100% valid and guaranteed.</p>`,
      },
      {
        question: `How will I receive my tickets?`,
        answer: `<p>Tickets are usually delivered by post, shipping courier, hand delivery, pick-up point, box office collection, or electronically. It is not an option for customers to decide it but for us in order to get them delivered on time. The delivery information will be sent by email in due time and updated on your account on our site.</p>`,
      },
      {
        question: `I have not received a confirmation email for my order, what should I do?`,
        answer: `<p>First, please check your spam/junk folders to be sure it was not misdirected. If you don't find it, contact us to be sure your email address is working properly for us to communicate with you. You can also download and print your order confirmation from your client account.</p>`,
      },
      {
        question: `Will I be seated in the Home or Away section?`,
        answer: `<p>Purchased tickets are always seated with the home team unless they are specifically purchased as away tickets or in the "any available" category (any available means anywhere so it can be either home or away).
FootballTicketsLive</p>`,
      },
    ],
  }),
};
</script>
<style></style>
