<template>
  <div class="container--fluid pa-0">
    <the-header />
    <the-banner />
    <the-search />
    <the-upcoming-events />
    <the-features />
    <the-premier-league />
    <sell-your-ticket />
    <premier-club-info />
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import TheBanner from "../components/TheBanner.vue";
import TheSearch from "../components/TheSearch.vue";
import TheUpcomingEvents from "../components/TheUpcomingEvents.vue";
import TheFeatures from "../components/TheFeatures.vue";
import ThePremierLeague from "../components/ThePremierLeague.vue";
import SellYourTicket from "../components/SellYourTicket.vue";
import PremierClubInfo from "../components/PremierClubInfo.vue";
import TheFooter from "../components/TheFooter.vue";

export default {
  name: "Home",
  components: {
    TheHeader,
    TheBanner,
    TheSearch,
    TheUpcomingEvents,
    TheFeatures,
    ThePremierLeague,
    SellYourTicket,
    TheFooter,
    PremierClubInfo,
  },
};
</script>
