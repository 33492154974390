import http from "@/utils/request";
const fixtureHomeEndpoint = `public/home/fixture`;
const fixtureEndpoint = `public/fixture`;
export default {
  getFeatured: (payload) => http.post(`${fixtureHomeEndpoint}/get`, payload),
  getUpcoming: (payload) =>
    http.post(`${fixtureHomeEndpoint}/upcoming/get`, payload),
  getUpcomingByType: (payload) =>
    http.post(`${fixtureEndpoint}/get/upcoming`, payload),
  getFixtureDetails: (payload) => http.post(`${fixtureEndpoint}/get`, payload),
  getResultsforFixturesFilter: (payload) =>
    http.post(`${fixtureEndpoint}/filters/get`, payload),
  getFixturesByFilter: (payload) =>
    http.post(`${fixtureEndpoint}/get/byFilter`, payload),
};
