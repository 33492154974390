<template>
  <div v-if="alert">
    <v-snackbar v-model="alert" :right="true" :top="true" :color="alert.type">
      <template v-slot:action="{ attrs }">
        <v-btn icon color="#fff">
          <v-icon v-bind="attrs" color="#fff" small @click="alert = false">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
      <span v-if="alert.display_message"> {{ alert.display_message }}</span>
      <span v-else> {{ alert.props }}</span>
      <ul v-if="alert.error && alert.error.details">
        <template v-for="i in alert.error.details">
          <li :key="i">
            {{ i }}
          </li>
        </template>
      </ul>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    alert: {
      get() {
        return this.$store.state.banner.alert;
      },
      set(value) {
        this.$store.commit("banner/STOP_LOADING", value);
      },
    },
  },
  watch: {
    alert(val) {
      val &&
        setTimeout(() => {
          this.alert = false;
        }, 3000);
    },
  },
};
</script>
