<template>
  <v-app>
    <v-main>
      <div class="container--fluid pa-0">
        <alerts />
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Alerts from "@/components/Alerts.vue";
export default {
  name: "App",
  data: () => ({}),
  components: {
    Alerts,
  },
  created() {
    (function (d) {
      var s = d.createElement("script");
      s.async = true;
      s.src = "https://cdn-eu.seatsio.net/chart.js";
      (d.head || d.body).appendChild(s);
    })(document);
  },
};
</script>
