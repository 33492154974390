import asyncMiddleware from "@/utils/ApiHandler.js";
import collection from "@/api/auth";
const state = {
  wait: false,
  props: null,
  menus: null,
};

const mutations = {
  START_LOADING(state, payload) {
    state.wait = true;
    state.props = payload;
  },
  STOP_LOADING(state, payload) {
    state.wait = false;
    state.props = payload;
  },
  SET_MENUS(state, payload) {
    state.menus = payload;
  },
};

const actions = {
  async MENU({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.menu(payload);
        if (data) {
          commit("SET_MENUS", data.data);
        }
        return data;
      },
      commit,
      dispatch,
      "MENU",
      false
    );
  },
  start({ commit }, payload) {
    commit("START_LOADING", payload);
  },
  end({ commit }, payload) {
    commit("STOP_LOADING", payload);
  },
  handleSuccess({ dispatch }, successObj) {
    if (successObj.notify) {
      dispatch(
        "banner/OPEN",
        {
          type: "success",
          props: successObj.response.message,
        },
        { root: true }
      );
    }
  },
  handleError({ dispatch }, errorObj) {
    const props = errorObj.error.response
      ? errorObj.error.response.data.message
      : errorObj.error.message;
    dispatch(
      "banner/OPEN",
      {
        type: "error",
        props,
      },
      { root: true }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
