<template>
  <v-footer padless>
    <div class="footer-sec">
      <v-row class="pb-8">
        <v-col cols="12" md="12">
          <div class="footerlogo-sec d-flex justify-space-between">
            <div
              class="d-flex justify-space-between align-center xs-footer"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''"
            >
              <img src="@/assets/images/footerlogo.svg" alt="" />
              <h3 class="more-info" v-if="$vuetify.breakpoint.mdAndDown">
                Get Ticket Alerts, News and more:
              </h3>
            </div>
            <v-form ref="form">
              <div class="d-flex align-items-center justify-center">
                <h3 class="more-info" v-if="!$vuetify.breakpoint.mdAndDown">
                  Get Ticket Alerts, News and more:
                </h3>
                <div class="d-flex subscribe-info">
                  <v-text-field
                    class="input-details"
                    solo
                    hide-details="auto"
                    placeholder="Your Name"
                    :rules="rules.name"
                    v-model="form.name"
                  ></v-text-field>
                  <v-text-field
                    class="input-details"
                    solo
                    hide-details="auto"
                    placeholder="Email Address"
                    :rules="rules.email"
                    v-model="form.email"
                  ></v-text-field>
                  <v-btn
                    :ripple="false"
                    class="mr-2 updated"
                    outlined
                    color="primary"
                    @click="submit"
                  >
                    Stay Updated
                    <img
                      class="ml-2"
                      src="@/assets/images/greenarrow.svg"
                      alt=""
                    />
                  </v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </v-col>
      </v-row>
      <v-divider class="line top"></v-divider>
      <div>
        <v-row class="footer-menu">
          <v-col cols="12" md="3" sm="6">
            <h3 class="footer-menu-title">Football Tickets Live</h3>
            <ul class="menu-list">
              <li
                class="menu-items"
                v-for="(item, index) in FootballTicketsLiveMenu"
                :key="index"
              >
                <router-link class="text-decoration-none" :to="item.path">
                  <a
                    v-if="
                      item.path.toLowerCase() === '/account' &&
                      $store.getters.token &&
                      $store.state.auth.loggedInUser
                    "
                    class="text-decoration-none text-color-white-200"
                    :href="item.path"
                  >
                    {{ item.text }}
                  </a>
                  <a
                    v-if="item.path.toLowerCase() !== '/account'"
                    class="text-decoration-none text-color-white-200"
                    :href="item.path"
                  >
                    {{ item.text }}
                  </a>
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <h3 class="footer-menu-title">Top Club Tickets</h3>
            <ul class="menu-list">
              <li
                class="menu-items"
                v-for="(item, index) in TopClubTicketsMenu"
                :key="index"
              >
                <router-link
                  class="text-decoration-none text-color-white-200"
                  :to="`/teams/${item.slug}`"
                >
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <h3 class="footer-menu-title">Top Stadium Tickets</h3>
            <ul class="menu-list">
              <li
                class="menu-items"
                v-for="(item, index) in TopStadiumTicketMenu"
                :key="index"
              >
                <router-link
                  class="text-decoration-none text-color-white-200"
                  :to="`/stadiums/${item.slug}`"
                >
                  {{ item.name }} Tickets
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <h3 class="footer-menu-title">Buy Football Tickets</h3>
            <ul class="menu-list">
              <li
                class="menu-items"
                v-for="(item, index) in BuyFootballTicketsMenu"
                :key="index"
              >
                <router-link
                  class="text-decoration-none text-color-white-200"
                  :to="`/tournaments/${item.slug}`"
                >
                  {{ item.title }} Tickets
                </router-link>
              </li>
            </ul>
            <h3 class="footer-menu-title mt-7">We Accept</h3>
            <div>
              <img class="mr-1" src="@/assets/images/Maestro.svg" alt="" />
              <img class="mr-1" src="@/assets/images/Mastercard.svg" alt="" />
              <img class="mr-1" src="@/assets/images/Visa.svg" alt="" />
              <img
                class="mr-1"
                src="@/assets/images/AmericanExpress.svg"
                alt=""
              />
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider class="line"></v-divider>
      <div class="copy-right">
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
        >
          <div>
            <span class="mr-1">
              © Football Tickets Live {{ new Date().getFullYear() }}. All rights
              reserved |
            </span>
          </div>
          <div>
            <router-link
              class="text-decoration-none text-color-white-200"
              to="/terms-and-conditions"
            >
              Terms & Conditions |
            </router-link>
            <router-link
              class="text-decoration-none text-color-white-200"
              to="/privacy-policy"
            >
              Privacy |
            </router-link>
            <router-link
              class="text-decoration-none text-color-white-200"
              to="/cookies"
            >
              Cookie Policy
            </router-link>
          </div>
        </div>
        <div
          :class="$vuetify.breakpoint.mdAndDown ? 'mt-2' : ''"
          class="xs-web"
        >
          <a
            href="https://www.creativeideaz.co.uk/web-design-birmingham"
            target="_blank"
            class="text-decoration-none text-color-white-200"
          >
            Website Design &
          </a>
          <a
            href="https://www.creativeideaz.co.uk/"
            target="_blank"
            class="text-decoration-none text-color-white-200"
          >
            Marketing by
          </a>
          <a
            href="https://www.creativeideaz.co.uk"
            target="_blank"
            class="text-decoration-none text-color-white-200"
          >
            Creative Ideaz
          </a>
        </div>
      </div>
    </div>
  </v-footer>
</template>
<script>
export default {
  name: "TheFooter",
  data: () => ({
    // {{url}}/api/v1/public/home/team/featured/get
    // {{url}}/api/v1/stadiums/get
    // {{url}}/api/v1/public/home/tournaments/featured/get
    form: {
      name: null,
      email: null,
    },
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Please enter a valid email address e.g. example@example.com",
      ],
      name: [(v) => !!v || "Please enter first name"],
    },
    FootballTicketsLiveMenu: [
      {
        path: "/account",
        text: "My Account",
      },
      {
        path: "/about-us",
        text: "About Us",
      },
      {
        path: "/contact-us",
        text: "Contact Us",
      },
      {
        path: "/faqs",
        text: "FAQs",
      },
      {
        path: "/disclaimer",
        text: "Disclaimer",
      },
      {
        path: "/blog",
        text: "News",
      },
      {
        path: "/sell-your-tickets",
        text: "Sell Your Tickets",
      },
    ],
    TopClubTicketsMenu: [
      "Manchester United tickets",
      "Liverpool tickets",
      "Manchester City tickets",
      "Tottenham Hotspur tickets",
      "Arsenal tickets",
      "Chelsea tickets",
    ],
    TopStadiumTicketMenu: [
      "Wembley Stadium Tickets",
      "Old Trafford Tickets",
      "Emirates Stadium tickets",
      "Stamford Bridge tickets",
      "Etihad Stadium tickets",
      "Olympic Stadium tickets",
    ],
    BuyFootballTicketsMenu: ["Premier League tickets", "FA Cup Tickets"],
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("static/NEWS_LETTER", this.form)
          .then((res) => {
            if (res.result === "success") {
              this.$refs.form.reset();
              this.success = true;
              this.loading = false;
            } else {
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch("teams/GET_FEATURED").then((response) => {
      this.TopClubTicketsMenu = response.data;
    });
    this.$store.dispatch("stadiums/GET_FEATURED").then((response) => {
      this.TopStadiumTicketMenu = response.data;
    });
    this.$store.dispatch("tournaments/GET_FEATURED").then((response) => {
      this.BuyFootballTicketsMenu = response.data;
    });
  },
};
</script>
<style></style>
