<template>
  <v-toolbar
    id="navbar-menu"
    class="py-4 menu-wrap"
    elevation="0"
    :class="[
      $vuetify.breakpoint.mdAndUp ? 'px-15' : '',
      sticky ? 'sticky-menu' : '',
    ]"
  >
    <router-link to="/" tag="span" style="cursor: pointer">
      <img src="../../assets/images/logo.svg" alt="" />
    </router-link>
    <v-toolbar-items
      class="hidden-xs-only menu-content"
      v-if="!$vuetify.breakpoint.mdAndDown"
    >
      <v-menu
        v-for="(item, key) in menus"
        :key="key"
        :close-on-content-click="true"
        :nudge-width="200"
        offset-y
        class="cust-list"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            active-class="v-item--active"
            class="menu-item"
            text
            :to="item.path"
            elevation="0"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            {{ key }}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item
              :to="`/${key}/${i.slug}`"
              link
              v-for="i in item"
              :key="i.slug"
            >
              <v-list-item-avatar>
                <img
                  :src="`${key === 'teams' ? i.logo : i.image}`"
                  :alt="i.title"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ i.title }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn
        v-for="(item, key) in menuItems"
        :key="key"
        :ripple="false"
        :to="`/${item.slug}`"
        active-class="v-item--active"
        class="menu-item"
        text
        elevation="0"
      >
        {{ item.title }}
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <!-- small screen devices -->
    <div v-if="!$vuetify.breakpoint.smAndDown">
      <div id="badge-ribbon"></div>
    </div>
    <!-- <div id="badge-120" style="max-width: 120px"></div> -->
    <div class="d-flex menu-right">
      <!-- <img
        src="../../assets/images/feefo.png"
        alt=""
        v-if="!$vuetify.breakpoint.xsOnly"
      /> -->
      <a :href="whatsAppLink" target="_blank">
        <img
          class="whatsapp"
          src="../../assets/images/Whatsapp.svg"
          alt="whats-app"
          v-if="!$vuetify.breakpoint.smAndDown"
        />
      </a>
      <v-menu
        elevation="0"
        :close-on-content-click="false"
        content-class="mobile-menu"
        offset-y
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            class="ml-2 btn-menu"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            <img
              src="../../assets/images/mob-menu-ico.svg"
              class="mr-2"
              alt=""
            />
            <!-- Menu -->
          </v-btn>
        </template>
        <v-list
          elevation="0"
          class="mobile-menu-list pl-4"
          v-if="$vuetify.breakpoint.mdAndDown"
          link
        >
          <template v-for="(item, key, index) in menus">
            <v-list-group
              class="xs-menu-group"
              v-if="item"
              v-model="item.active"
              :key="index"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ key | capitalize }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, index) in item"
                :key="index"
                class="d-flex justify-center align-center"
                link
                :to="`/${key}/${child.slug}`"
              >
                <v-list-item-content>
                  <div class="d-flex justify-center align-center">
                    <v-list-item-avatar>
                      <img
                        :src="`${key === 'teams' ? child.logo : child.image}`"
                        :alt="child.title"
                      />
                    </v-list-item-avatar>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                class="mobile-clubs mb-4"
                elevation="0"
                v-if="$vuetify.breakpoint.mdAndDown"
              >
                View all Clubs
                <img
                  class="ml-2"
                  src="../../assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </v-list-group>
          </template>
          <template v-for="(item, index) in menuItems">
            <v-list-group
              class="xs-menu-group"
              v-if="item.subMenu"
              v-model="item.active"
              :to="`/${item.slug}`"
              :key="index"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content :to="`/${item.slug}`">
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, index) in item.subMenu"
                :key="index"
                class="d-flex justify-center align-center"
              >
                <v-list-item-content>
                  <div class="d-flex justify-center align-center">
                    <img
                      class="mr-2"
                      :src="child.src"
                      width="24"
                      height="24"
                      v-if="child.src"
                    />
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                class="mobile-clubs mb-4"
                elevation="0"
                v-if="$vuetify.breakpoint.mdAndDown"
              >
                View all Clubs
                <img
                  class="ml-2"
                  src="../../assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </v-list-group>
            <v-list-item
              class="xs-menu-item"
              v-if="!item.subMenu"
              :to="item.path"
              :key="item.title"
            >
              <v-list-item-title :to="item.path">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-btn
            class="mobile-whatsapp"
            elevation="0"
            v-if="$vuetify.breakpoint.smAndDown"
            @click="login"
          >
            <img src="../../assets/images/signin.svg" alt="" class="mr-2" />
            Sign In
          </v-btn>
          <v-btn
            class="mobile-whatsapp"
            elevation="0"
            v-if="$vuetify.breakpoint.smAndDown"
            :href="whatsAppLink"
            target="_blank"
          >
            <img class="mr-4" src="../../assets/images/Whatsapp.svg" alt="" />
            Enquire via WhatsApp
          </v-btn>
        </v-list>
      </v-menu>
    </div>
  </v-toolbar>
</template>
<script>
export default {
  name: "DynamicTopMenu",
  data() {
    return {
      whatsAppLink: process.env.VUE_APP_WHATSAPP_LINK,
      sticky: false,
      menuItems: [
        {
          title: "Most Popular",
          icon: "mdi-chevron-down",
          slug: "most-popular-matches",
        },
        { title: "Sell Your Tickets", slug: "sell-your-tickets" },
      ],
    };
  },
  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById("navbar-menu");
      if (window.scrollY > navBar.offsetTop) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    };
    this.loadReviewWidget();
  },
  computed: {
    menus() {
      return this.$store.state.app.menus;
    },
  },
  methods: {
    login() {
      this.$router.push({ path: "/login" });
    },
    loadReviewWidget() {
      // eslint-disable-next-line no-undef
      // reviewsBadgeModern("badge-120", {
      //   store: "footballticketslive.com",
      //   primaryClr: "#000000",
      //   starsClr: "#000000",
      // });
      // eslint-disable-next-line no-undef
      reviewsBadgeRibbon("badge-ribbon", {
        store: "footballticketslive.com",
        size: "small",
      });
    },
  },
};
</script>
<style scoped>
.sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
}
</style>
