import collection from "@/api/users";

import asyncMiddleware from "@/utils/ApiHandler.js";

const state = {
  list: [],
  item: null,
  usersAddress: null,
  usersAddressPagination: null,
};

const mutations = {
  SET_USERS_ADDRESS: (state, data) => {
    state.usersAddress = data;
  },
  SET_USERS_ADDRESS_PAGINATION: (state, data) => {
    state.usersAddressPagination = data;
  },
};

const actions = {
  async GET_LOGGEDIN_USER_ADDRESS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.getAddress(payload);
        if (data) {
          commit("SET_USERS_ADDRESS", data.data);
          let p = Object.assign({}, data.data);
          delete p.data;
          commit("SET_USERS_ADDRESS_PAGINATION", p);
        }
        return data;
      },
      commit,
      dispatch,
      "GET_LOGGEDIN_USER_ADDRESS",
      false
    );
  },
  async CREATE_USER_ADDRESS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.createAddress(payload);
        return data;
      },
      commit,
      dispatch,
      "CREATE_USER_ADDRESS",
      true
    );
  },
  async UPDATE_USER_ADDRESS({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.updateAdrress(payload.id, payload);
        return data;
      },
      commit,
      dispatch,
      "UPDATE_USER_ADDRESS",
      true
    );
  },
  async UPDATE_USER({ commit, dispatch }, payload) {
    return await asyncMiddleware(
      async function () {
        const { data } = await collection.updateUser(payload);
        return data;
      },
      commit,
      dispatch,
      "UPDATE_USER",
      true
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
