<template>
  <div class="box-wrapper-main">
    <div
      class="box-left-brd"
      :style="{
        '--colour': boxOptions.BgColor,
        '--circle-width': `${boxOptions.circleWidth}px`,
        '--circle-width2': `${boxOptions.circleWidth + 1}px`,
        '--width': `${boxOptions.width}px`,
      }"
    ></div>
    <div
      class="box-content-wrapper"
      :style="`background-color: ${boxOptions.BgColor}`"
    >
      <slot name="box-content"></slot>
    </div>
    <div
      class="box-right-brd"
      :style="{
        '--colour': boxOptions.BgColor,
        '--circle-width': `${boxOptions.circleWidth}px`,
        '--circle-width2': `${boxOptions.circleWidth + 1}px`,
        '--width': `${boxOptions.width}px`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    boxOptions: {
      type: Object,
      default: () => {
        return {};
      },
      require: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.box-left-brd {
  position: relative;
  width: var(--width);
  background: radial-gradient(
    circle at 0% 50%,
    transparent var(--circle-width),
    var(--colour) var(--circle-width2)
  );
  margin: 0 auto;
}
.box-right-brd {
  position: relative;
  width: var(--width);
  background: radial-gradient(
    circle at 100% 50%,
    transparent var(--circle-width),
    var(--colour) var(--circle-width2)
  );
  margin: 0 auto;
}
.box-content-wrapper {
  display: flex;
  background-color: var(--colour);
  position: relative;
  justify-content: space-between;
}
.box-wrapper-main {
  display: flex;
  flex-wrap: nowrap;
}
</style>
