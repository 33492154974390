import http from "@/utils/request";
const fixtureEndpointHomePage = `public/home/team`;
const fixtureEndpointTeamPage = `public/team`;
export default {
  getFeatured: (payload) =>
    http.post(`${fixtureEndpointHomePage}/featured/get`, payload),
  getTeamDetailsBySlug: (payload) =>
    http.post(`${fixtureEndpointTeamPage}/get`, payload),
  getNearbyTeams: (payload) =>
    http.post(`${fixtureEndpointTeamPage}/get/nearby`, payload),
  getNearbyStadiums: (payload) =>
    http.post(`${fixtureEndpointTeamPage}/get/nearby/stadium`, payload),
  getTeamsFixtures: (payload) =>
    http.post(`${fixtureEndpointTeamPage}/get/fixtures`, payload),
};
