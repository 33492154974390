import axios from "axios";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
  headers: { "Content-Type": "application/json" },
});

service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    console.log("Request Error", error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    // console.log("success Response", response);
    if (response.status !== 200 && response.status !== 201) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    } else {
      return response;
    }
  },
  (error) => {
    // console.log("interceptor response error", error); // for debug
    // console.log("interceptor response error response - ", error.response); // for debug
    // console.log("interceptor response error message - ", error.message); // for debug
    return Promise.reject(error);
  }
);
export default service;
