var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"find-tickets-wrapper"},[_c('div',[_c('BoxSubtract',{attrs:{"boxOptions":_vm.$vuetify.breakpoint.smAndDown ? _vm.BxOptionsXs : _vm.BxOptionsLg}},[_c('template',{slot:"box-content"},[_c('div',{staticClass:"search-wrapper"},[_c('div',{staticClass:"search-form",attrs:{"outlined":""}},[_c('div',{staticClass:"search-form-wrap d-flex align-center justify-center",class:_vm.$vuetify.breakpoint.xsOnly ? 'flex-column' : ''},[_c('div',{staticClass:"search-txt",class:_vm.$vuetify.breakpoint.xsOnly ? 'mb-3' : ''},[_vm._v(" Find your tickets ")]),_c('div',{staticClass:"date d-flex align-center justify-center",class:_vm.$vuetify.breakpoint.xsOnly ? '' : 'mr-2'},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filterPayload.start_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filterPayload, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filterPayload, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-input vertical",attrs:{"value":_vm.dateUkFormat(_vm.filterPayload.start_date),"hide-details":"auto","placeholder":"Date from"}},'v-text-field',attrs,false),on),[_c('template',{slot:"prepend-inner"},[_c('img',{staticClass:"calendar",attrs:{"src":require("@/assets/images/calendar.svg")}})])],2)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},model:{value:(_vm.filterPayload.start_date),callback:function ($$v) {_vm.$set(_vm.filterPayload, "start_date", $$v)},expression:"filterPayload.start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"ripple":false,"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"ripple":false,"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filterPayload.start_date)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.filterPayload.end_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filterPayload, "end_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filterPayload, "end_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-input",attrs:{"value":_vm.dateUkFormat(_vm.filterPayload.end_date),"hide-details":"auto","placeholder":"Date to"}},'v-text-field',attrs,false),on),[_c('template',{slot:"prepend-inner"},[_c('img',{staticClass:"calendar",attrs:{"src":require("@/assets/images/calendar.svg")}})])],2)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},model:{value:(_vm.filterPayload.end_date),callback:function ($$v) {_vm.$set(_vm.filterPayload, "end_date", $$v)},expression:"filterPayload.end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.filterPayload.end_date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"auto-wrapper"},[_c('v-autocomplete',{staticClass:"date-input xs-location",attrs:{"items":_vm.teams,"loading":_vm.isLoading,"search-input":_vm.search,"clearable":"","multiple":"","hide-details":"auto","hide-selected":"","item-text":"title","item-value":"id","placeholder":"City / Team","menu-props":{ maxHeight: 400 }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for your favorite "),_c('strong',[_vm._v("Teams")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"left":""}},[_c('v-img',{attrs:{"src":item.logo}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]}}]),model:{value:(_vm.filterPayload.team_id),callback:function ($$v) {_vm.$set(_vm.filterPayload, "team_id", $$v)},expression:"filterPayload.team_id"}})],1),_c('v-btn',{staticClass:"search-btn",on:{"click":_vm.searchFixtures}},[_c('img',{attrs:{"src":require("@/assets/images/search-arrow.png"),"alt":""}})])],1)])])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }