<template>
  <div>
    <the-top-navbar />
    <the-menu />
    <the-bottom-navbar />
  </div>
</template>
<script>
import TheMenu from "./HeaderMolecules/TheMenu.vue";
import TheTopNavbar from "./HeaderMolecules/TheTopNavbar.vue";
import TheBottomNavbar from "./HeaderMolecules/TheBottomNavbar.vue";
export default {
  components: { TheTopNavbar, TheMenu, TheBottomNavbar },
  name: "Header",
  mounted() {
    this.fetchMenu();
  },
  methods: {
    async fetchMenu() {
      await this.$store.dispatch("app/MENU", {
        getters: ["teams", "tournaments", "stadiums"],
      });
    },
  },
};
</script>
