<template>
  <v-card
    v-if="teamInfo"
    :to="`/teams/${teamInfo.team.slug}`"
    class="premier-card"
    elevation="0"
  >
    <!-- <v-img :src="@/assets/images/manchester.png"></v-img> -->
    <v-img :src="teamInfo.team.image"></v-img>
    <div class="team-logo">
      <img width="64" height="64" :src="teamInfo.team.logo" />
      <!-- <img width="64" height="64" src="@/assets/images/cardlogo1.svg" /> -->
    </div>
    <div class="text-center league-info">
      <div class="team-name">
        {{ teamInfo.team.name }}
      </div>
      <p v-if="teamInfo.team.team_stadium" class="stadium-name">
        {{ teamInfo.team.team_stadium.name }}
      </p>
      <v-btn
        :ripple="false"
        text
        :to="`/teams/${teamInfo.team.slug}`"
        class="club-details"
      >
        View Club
        <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg" />
      </v-btn>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "PremierLeagueCardMolecule",
  props: { teamInfo: { Type: Object, required: true } },
};
</script>
<style></style>
