<template>
  <v-system-bar
    class="px-15 bottom-navbar bg-grey-light"
    :class="$vuetify.breakpoint.xsOnly ? 'py-0' : 'py-2'"
    :height="$vuetify.breakpoint.xsOnly ? '' : '40'"
  >
    <v-container fluid :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
      <v-row v-if="!$vuetify.breakpoint.xsOnly">
        <template v-for="(item, index) in Items">
          <v-col class="d-flex btm-menu" cols="12" md="3" sm="3" :key="index">
            <img class="mr-2" :src="item.icon" alt="" />{{ item.title }}</v-col
          >
        </template>
      </v-row>
      <v-carousel
        height="40"
        hide-delimiters
        :show-arrows="false"
        v-if="$vuetify.breakpoint.xsOnly"
        class="bottom-banner"
      >
        <v-carousel-item
          v-for="(item, i) in Items"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-row class="fill-height flex-row" align="center" justify="center">
            <div class="d-flex flex-row align-center justify-center">
              <img class="mr-2" :src="item.icon" alt="" />{{ item.title }}
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-container>
  </v-system-bar>
</template>
<script>
export default {
  name: "TheBottomNavbar",
  data() {
    return {
      Items: [
        {
          title: "Order Guarantee",
          icon: require("../../assets/images/green-tick.svg"),
        },
        {
          title: "Valid & Authentic Tickets",
          icon: require("../../assets/images/green-tick.svg"),
        },
        {
          title: "Secure & Safe Transaction",
          icon: require("../../assets/images/green-tick.svg"),
        },
        {
          title: "Customer Care Team",
          icon: require("../../assets/images/green-tick.svg"),
        },
      ],
    };
  },
};
</script>
